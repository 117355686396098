let shadowRoot;
const input = document.createElement('input');
input.type = 'text';
input.name = 'priority';
input.id = 'priority';
input.setAttribute('data-label', 'priority');
input.value = '3K';
input.style = "display:none;"

const qualification = document.createElement('input');
qualification.type = 'text';
qualification.name = 'qualification';
qualification.id = 'qualification';
qualification.setAttribute('data-label', 'qualification');
qualification.value = 0;
qualification.style = "display:none;"

const isQualified = document.createElement('input');
isQualified.type = 'text';
isQualified.name = 'isQualified';
isQualified.id = 'isQualified';
isQualified.setAttribute('data-label', 'isQualified');
isQualified.value = false;
isQualified.style = "display:none;"

window.addEventListener('heyflow-init', (event) => {
shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
    const form = shadowRoot.querySelector(`[id="embedded-test"]`);
    form.appendChild(input);
    form.appendChild(qualification);
    form.appendChild(isQualified);
});



window.addEventListener('heyflow-change', (event) => {
    console.log('heyflow-change', event.detail.fieldsSimple);
    if (event.detail.fieldsSimple.hasHearingAid == 'true'){
        switch(event.detail.fieldsSimple.howOldIsHearingAid){
        case '5':
            input.value = '1B';
            qualification.value = 1;
            isQualified.value = true;
            break;
        case '4':
            if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
            input.value = '1C';
            qualification.value = 1;
            isQualified.value = true;
            break;}
            else{
            input.value = '3G'
            qualification.value = 0;
            isQualified.value = false;
            break;    
            }
        case '3':
            if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
            input.value = '3G';
            qualification.value = 1;
            isQualified.value = true;
            break;}
            else{
               input.value = '3H';
               qualification.value = 1;
               isQualified.value = true;
            break; 
            }
        case '2':
             if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
            input.value = '3H';
            qualification.value = 1;
            isQualified.value = true;
            break;}
            else{
               input.value = '3I';
               qualification.value = 1;
               isQualified.value = true;
            break; 
            }
        case '1':
             if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
                input.value = '3I';
                qualification.value = 1;
                isQualified.value = true;
                break;}
            else{
                input.value = '3J';
                qualification.value = 1;
                isQualified.value = true;
                break; 
            }
        }
        
    }
    else{
    
    if (event.detail.fieldsSimple.hasENTPrescription == 'true'){
                input.value = '1E';
                qualification.value = 1;
                isQualified.value = true;
    }
    else

    if (event.detail.fieldsSimple.userAge > 49) {
                input.value = '1F';
                qualification.value = 1;
                isQualified.value = true;
    }
    
    } 
    
});



